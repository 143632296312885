import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HomeLayout from '../layouts/Home';
import SEO from '../components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomeLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Steven Frieson's Website" description="Learn about Steven's current projects and read articles he's written." mdxType="SEO" />
    <h1 className="visually-hidden">Steven Frieson's Website</h1>
    <p className="color--display fontWeight--bold fontSize--6">Hi!</p>
    <p className="color--display fontWeight--bold fontSize--4">I am Steven.</p>
    <p>{`Welcome to my website. This is my small corner of the internet, which I'm filling with some stuff I care about. Considering I am a web developer by profession, most of the content here will slant that direction, but I can't promise that I won't deviate from that topic.`}</p>
    <p>{`More specifically, I'm a front end developer working predominantly in the web technologies: `}<strong parentName="p">{`HTML`}</strong>{`, `}<strong parentName="p">{`CSS`}</strong>{`, and `}<strong parentName="p">{`JavaScript`}</strong>{`. I also care greatly about solving problems for people whether that be end users on a website or fellow developers.`}</p>
    <p>{`I hope this website can be informative for you. Continue to check back because, as most things in development, this site is an iterative process.`}</p>
    <p>{`Steven 😜`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      